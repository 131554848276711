
import { defineComponent } from "vue";
import { ElMessage, ElLoading } from "element-plus";
import ColorPicker from "@/components/Input/ColorPicker.vue";
import _ from "lodash";
import helpers from "@/helpers/global";
import {
  getStorage,
  ref,
  uploadString,
  getDownloadURL,
} from "firebase/storage";

let loadingEl: any;

export default defineComponent({
  name: "MyCompany",
  components: {
    ColorPicker,
  },
  computed: {
    paymentEnabled() {
      return process.env.VUE_APP_PAYMENT_ENABLED === "on";
    },
    user(): any {
      return this.$store.getters["user/user"];
    },
    team(): any {
      return this.$store.getters["team/team"];
    },
    companyLogoUrl(): any {
      const companyLogoUrl = this.doEditTeam
        ? this.$store.getters["team/companyLogoUrl"]
        : this.$store.getters["user/companyLogoUrl"];
      return companyLogoUrl;
    },
    loading(): any {
      return this.$store.getters["payment/loading"];
    },
    isImage(): any {
      return (
        this.selectedFile && (this.selectedFile as any).type.includes("image")
      );
    },
    typeOfOrgOptions(): any {
      return this.$store.getters["toolbox/typeOfOrgOptions"];
    },
    // @see views/Assessments/Create/SetupValues&Traits.vue
    valuesAndTraits(): any {
      const valuesAndTraits =
        this.$store.getters["assessmentSection/valuesAndTraits"];
      if (
        valuesAndTraits &&
        valuesAndTraits.scenarios &&
        valuesAndTraits.scenarios.definingYourValues &&
        valuesAndTraits.scenarios.definingYourValues.questions
      ) {
        // helpers.shuffleArray(
        //   valuesAndTraits.scenarios.definingYourValues.questions
        // );
        valuesAndTraits.scenarios.definingYourValues.questions.sort(
          (a: any, b: any) => {
            const nameA = a.title.toUpperCase(); // ignore upper and lowercase
            const nameB = b.title.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            // names must be equal
            return 0;
          }
        );
      }
      return valuesAndTraits;
    },
    members(): any[] {
      return this.$store.getters["team/members"];
    },
  },
  watch: {
    loading(value: any) {
      if (value) {
        loadingEl = ElLoading.service({
          lock: true,
          text: "Login..",
          background: "#ffffff90",
        });
      } else {
        loadingEl.close();
      }
    },
    color(value) {
      this.form.color = value;
      if (value !== "") {
        const rgx = /^#[0-9a-f]{3,6}$/i;
        this.hexValidate = !rgx.test(value);
      } else {
        this.hexValidate = false;
      }
    },
  },
  async mounted() {
    if (!this.$store.getters["user/user"]) {
      await this.$store.dispatch("user/loadCurrentUser");
    }
    await this.$store.dispatch(
      "assessmentSection/loadSetupValuesAndTraits",
      null,
      {
        root: true,
      }
    );

    // @todo common model to edit
    const user = this.$store.getters["user/user"] || {};
    let hasTeam = false;
    let isPersonal = true;
    let team = null;
    if (user && user.teamId) {
      await this.$store.dispatch(
        "team/getTeam",
        { teamId: user.teamId },
        { root: true }
      );
      team = this.$store.getters["team/team"];
      if (team) {
        hasTeam = true;
        isPersonal = team.personal || false;

        const member = _.find(team.members, {
          email: user.email,
          role: "admin",
        });
        this.isAdmin = _.isObject(member);
      }
    }

    // @todo save to personal or current team (@deprecated - user model of binding data)

    this.doEditTeam = hasTeam && !isPersonal;
    if (this.doEditTeam) {
      this.targetModel = {
        color: team.color || "",
        companyName: team.companyName || "",
        typeOfOrg: team.typeOfOrg || "",
        coreValues: team.coreValues || [],
        missionAndVision: team.missionAndVision || "",
      };
    } else {
      this.targetModel = {
        color: user.color || "",
        companyName: user.companyName || "",
        typeOfOrg: user.typeOfOrg || "",
        coreValues: user.coreValues || [],
        missionAndVision: user.missionAndVision || "",
      };
    }

    if (user) {
      this.color = String(user.color);
    }

    this.loaded = true;
  },
  data() {
    return {
      loaded: false,
      doEditTeam: false,
      isAdmin: false,
      targetModel: {
        color: "",
        companyName: "",
        typeOfOrg: "",
        coreValues: [],
        missionAndVision: "",
      } as any,
      userDetail: {
        id: "",
        firstname: "",
        lastname: "",
        company_name: "",
        company_color: "#1B1C1E",
        logo: "",
        // link of image
        company_logo: "",
        imageFile: "",
      },
      loadingImageFile: false,
      companyUploadedFile: null,
      companyUploadedFileDataUrl: "",
      color: "",
      colors: "#fafafa",
      hexValidate: false,
      selectedFile: null as any,
      form: {
        // color: "#ffffff",
        color: "#000000", // default company color
        imageFile: "",
        companyUploadedFile: new File([], ""),
        companyUploadedFileDataUrl: "",
        companyLogoUrl: "",
        typeOfOrgSelected: "",
        coreValuesSelected: [] as string[],
        missionAndVision: "",
      },
      errors: {
        companyName: false,
        missionAndVision: false,
        typeOfOrgSelected: false,
        coreValuesSelected: false,
      },
    };
  },
  methods: {
    // handleAvatarSuccess(res: any) {
    //   this.form.imageFile = URL.createObjectURL(res.file);

    //   const reader = new FileReader();
    //   reader.addEventListener("load", (/*event: ProgressEvent*/) => {
    //     this.form.companyUploadedFile = res.file;
    //     this.form.companyUploadedFileDataUrl = String(reader.result);
    //   });
    //   reader.readAsDataURL(res.file);
    // },
    onColorChange(val: any) {
      this.color = val.hex;
      this.targetModel.color = val.hex;
    },
    handleFileUpload(event: any) {
      const file = event.target.files[0];
      this.selectedFile = file;

      if (file && file.type.includes("image")) {
        this.companyLogoUrl = URL.createObjectURL(file);
        this.handleAvatarSuccess({ file });
      } else {
        this.companyLogoUrl = "";
      }
    },
    validate(): boolean {
      let error = false;

      // reset all errors
      this.errors.companyName = false;
      this.errors.typeOfOrgSelected = false;
      this.errors.coreValuesSelected = false;
      this.errors.missionAndVision = false;

      if (!this.user.companyName.length) {
        this.errors.companyName = true;
        error = true;
      }

      // Not important
      // @see signup feature

      // if (!this.user.typeOfOrg || !this.user.typeOfOrg.length) {
      // if (!this.targetModel.typeOfOrg || !this.targetModel.typeOfOrg.length) {
      //   this.errors.typeOfOrgSelected = true;
      //   error = true;
      // }

      // if (!this.user.coreValues || !this.user.coreValues.length) {
      //   this.errors.coreValuesSelected = true;
      //   error = true;
      // }
      // if (!this.user.missionAndVision || !this.user.missionAndVision.length) {
      //   this.errors.missionAndVision = true;
      //   error = true;
      // }

      return !error;
    },
    async saveMyCompany() {
      if (!this.isAdmin) {
        return;
      }
      const user = this.$store.getters["user/user"] || {};
      const team = this.$store.getters["team/team"];

      if (!this.validate()) {
        return false;
      }

      if (_.isObject(this.color)) {
        this.color = (this.color as any).hex;
      }

      // user or team
      const targetToSaveObj = this.doEditTeam ? team : user;

      // copy values to target object to save
      targetToSaveObj.color = this.targetModel.color;
      targetToSaveObj.companyName = this.targetModel.companyName;
      targetToSaveObj.typeOfOrg = this.targetModel.typeOfOrg;
      targetToSaveObj.coreValues = this.targetModel.coreValues;
      targetToSaveObj.missionAndVision = this.targetModel.missionAndVision;

      let formDetails: any = _.pickBy(targetToSaveObj, (value, key) => {
        const pickedKeys = [
          "email",
          "firstName",
          "lastName",
          "companyName",
          "color",
          "typeOfOrg",
          "coreValues",
          "missionAndVision",
        ];
        const found = _.indexOf(pickedKeys, key) !== -1;
        const isEmpty = !value;
        return found && !isEmpty;
      });

      if (this.doEditTeam) {
        formDetails._id = team._id;
      }

      // @todo check is it dirty

      // upload image before saveUser
      const file: any = this.companyUploadedFile;
      const dataUrl = this.companyUploadedFileDataUrl;

      const action = this.doEditTeam ? "team/saveTeam" : "user/saveUser";

      if (file && dataUrl) {
        const storage = getStorage();
        const email = helpers.emailKey(user.email);
        const prefix = "company";
        const guid = helpers.guid();

        // @todo beautiful filename
        let filename = file.name;
        if (filename.indexOf(".") !== -1) {
          const chunks = filename.split(".");
          const ext = chunks.pop();
          filename = chunks.join(".") + "-" + guid + "." + ext;
        }
        const path = `images/users/${email}/${prefix}-${filename}`;
        const storageRef = ref(storage, path);
        uploadString(storageRef, dataUrl, "data_url").then(async (snapshot) => {
          getDownloadURL(snapshot.ref).then(async (url) => {
            //formDetails.companyLogoUrl = snapshot.metadata.fullPath;
            formDetails.companyLogoUrl = url;
            await this.$store.dispatch(action, formDetails, {
              root: true,
            });
          });
          ElMessage({
            message: (this as any).$t("popup.message.message_18b"),
            type: "success",
          });
        });
      } else {
        await this.$store.dispatch(action, formDetails, {
          root: true,
        });

        if (this.doEditTeam) {
          // nothing to do now
        } else {
          // @see auth/loginAsUser (alias)
          let userData: any = localStorage.getItem("userData");
          if (userData) {
            // console.log(userData, "userData");
            try {
              // Get logged in user
              userData = JSON.parse(
                decodeURIComponent(escape(atob(String(userData)))) || "{}"
              );
              userData.companyName = user.companyName;
              userData.typeOfOrg = user.typeOfOrg;
              userData.coreValues = user.coreValues;
              userData.missionAndVision = user.missionAndVision;

              // Set logged in user
              localStorage.setItem(
                "userData",
                btoa(unescape(encodeURIComponent(JSON.stringify(userData))))
              );
              await this.$store.dispatch("auth/setUserLogin", userData, {
                root: true,
              });
            } catch (error) {
              console.log(error, "error");
            }
          }
        }

        ElMessage({
          message: (this as any).$t("popup.message.message_18b"),
          type: "success",
        });
      }
    },
    handleAvatarSuccess(res: any) {
      this.userDetail.imageFile = URL.createObjectURL(res.file);

      const reader = new FileReader();
      reader.addEventListener("load", (/*event: ProgressEvent*/) => {
        this.companyUploadedFile = res.file;
        this.companyUploadedFileDataUrl = String(reader.result);
        // this.loadingImageFile = false;
      });
      reader.readAsDataURL(res.file);

      setTimeout(() => {
        this.loadingImageFile = false;
      }, 300);
    },
    beforeAvatarUpload(file: any) {
      const MAX_FILE_SIZE = 5;
      this.loadingImageFile = true;
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < MAX_FILE_SIZE;

      if (!isJPG) {
        this.loadingImageFile = false;
        ElMessage.error((this as any).$t("popup.message.message_5e"));
      }
      if (!isLt2M) {
        this.loadingImageFile = false;
        ElMessage.error((this as any).$t("popup.message.message_6e"));
      }

      return isLt2M && isJPG;
    },
    lang(obj: any, attr: string) {
      return helpers.objectLang(obj, attr);
    },
  },
});
